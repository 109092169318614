
import Vue from "vue";
import {links} from "@/config";

export default Vue.extend({
  name: "UserNotFound",
  methods:
    {
      goBack() {
          window.location.href = links.SIGN_IN;
      }
    }

});
